.ServiceCards h2{
    padding-left: 5%;
    padding-bottom: 20px;
    font-family:'Montserrat', sans-serif;
    padding-top: 40px;

}
.vertical-cards {
    display: flex;
    flex-wrap: wrap;
    width: 89%;
    margin: auto;
    /* border: 1px solid; */
    font-family:'Montserrat', sans-serif;
    gap: 20px ;
  }
  
  .Vcard {
    width: 32%;
    background-color: #f5f5f5;
    border-radius: 8px;
    margin-bottom: 20px;
    /* margin-left: 10px; */
    /* padding: 20px; */
    height: 350px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    border: 1px solid;
  }
  
  .Vcard:hover {
    transform: translateY(-5px);
  }
  
  .Vcard-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    /* border: 2px solid; */
    background-color: rgb(92, 92, 255);
    border-radius: 8px 8px 0 0;
    padding-left: 10px;
    padding: 20px;
    color: white;
  }
  
  .Vcard-title h3 {
    font-size: 18px;
    margin-left: 10px;
  }
  
  .Vcard-description {
    font-size: 18px;
    padding: 30px;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .vertical-cards{
      display: list-item;
      list-style: none;
      margin-left: 28px;
    }

    .Vcard {
      width: 94%;
    }

  }

