/* Footer.css */
.footer-container {
    background-color: #4349ff;
    color: #fff;
    padding-top: 50px 0;
    text-align: center;
    font-family:'Montserrat', sans-serif;

  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .footer-section {
    flex: 1;
    margin: 10px;
    text-align: center;
  }
  
  h3 {
    font-size: 18px;
    /* margin-bottom: 10px; */
  }
  
  ul.social-icons {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  
  ul.social-icons li {
    margin: 4px 5px;
    font-size: 35px;
  }
  
  ul.social-icons a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  ul.social-icons a:hover {
    color: #000000;
  }
  
  .company-logo {
    width: 70px; /* Adjust the logo size as needed */
    height: auto;
    border-radius: 4px;
  }
  
  .tagline {
    font-size: 30PX;
    font-weight: bold;
    color: #ffffff;
  }



.quick-links {
  flex: 1; /* Expand to take available space */
  padding: 20px; /* Add padding for spacing */
  color: #fff; /* Text color */
}

.quick-links h3 {
  font-size: 20px; /* Heading font size */
  margin-bottom: 10px; /* Spacing below the heading */
}

.quick-links ul {
  list-style-type: none; /* Remove bullet points from the list */
  padding: 0;
}

.quick-links li {
  margin-bottom: 8px; /* Spacing between each link */
}

.quick-links a {
  text-decoration: none; /* Remove underline from links */
  color: #fff; /* Link text color */
  font-weight: bold; /* Make links bold */
}

.quick-links a:hover {
    color: #000000;
}

.Privacy{
  background-color: #06018f;
  height: 25px;
  color: rgb(219, 223, 227);
  padding-top: 5px;
  display: flex;
  text-align: center;
  justify-content: center;
  gap: 8px;
}

.Privacy a{
  text-decoration: none;
  color: rgb(219, 223, 227);


}



@media (max-width: 768px) {
  .footer-content {
    display: list-item;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }  
  .footer-container {
    background-color: #4349ff;
    color: #fff;
    padding-top: 50px ;
    text-align: center;
    font-family:'Montserrat', sans-serif;

  }
  

}