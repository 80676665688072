.card-detail{
    padding-top: 120px;
    width: 77%;
    margin: auto;
    /* border: 2px solid; */
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    height: fit-content;
    font-family:'Montserrat', sans-serif;
    margin-bottom: 20px;
}

.card-detail h1{
    width: 60%;
    margin-bottom: 20px;
}

.boldtext h3{
        margin-bottom: 20px;
        margin-top: 20px;
}

@media (max-width: 768px) {
    .card-detail h1{
        width: 100%;
        margin-bottom: 20px;
    }

}