.TopmateDiv{
    height: 700px;
    padding-top: 80px;
}

@media (max-width: 768px) {

    .TopmateDiv{
        padding-top: 80px;
    }

}