/* TermsOfUse.css */
.TermsCont{
    padding-top: 100px;
    padding-bottom: 20px;
}

.terms-of-use-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .terms-of-use-heading {
    font-size: 24px;
    color: #321bff;
    margin-bottom: 20px;
  }
  
  .section-heading {
    font-size: 20px;
    color: #1a0d62;
    margin-top: 20px;
  }
  
  .terms-of-use-text {
    font-size: 16px;
    color: #555;
    margin-bottom: 15px;
  }
  
  /* Customize the colors, fonts, and styles to match your website's design. */
  