/* Navbar.css */

@import url('https://fonts.googleapis.com/css2?family=Borel&family=Montserrat&family=Quicksand:wght@700&display=swap');

.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 5px 2%;
  font-family: 'Montserrat', sans-serif;
  position: fixed;
  transition: 0.2s;
  height: 44px;
  /* border: 2px solid; */
  padding-bottom: 50px;
  z-index: 1;
}

.navbar a{
  text-decoration: none;
}

/* Styles for the navbar when scrolled */
.navbar.scrolled {
  position: fixed;
  top: 0;
  background-color: #fff;
  z-index: 1;
  transition: 0.3s;
}

.navbar-logo {
  font-family: sans-serif;
  display: flex;
  align-items: center; /* Center the logo vertically */
  margin-left: 60px;
}

.navbar-logo img {
  padding-top: 0px;
  height: 34px;
  /* Adjust the height of the logo as needed */
}

.navbar-logo h1 {
  font-size: 32px;
  color: rgb(21, 21, 84);
  padding-left: 5px;
  margin: 0; /* Remove default margin */
}

.navbar-links {
  list-style-type: none;
  padding: 0;
  display: flex;
}

.navbar-links li {
  margin-right: 28px;
}

.navbar-links a {
  text-decoration: none;
  color: black;
  font-weight: bold;
  padding-right: 20px;
}

.navbar-links a:hover {
  font-weight: bold;
  color: blue;
}

.Contact {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
              0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 120px;
  padding: 12px;
  margin-right: 85px;
  color: white;
  border: none;
  font-weight: bold;
  background-color: rgb(5, 5, 121);
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
}

/* Hamburger Icon */
.hamburger-icon {
  display: none;
  cursor: pointer;
  padding: 10px;
  margin-left: 20px;
}

.hamburger {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 5px 0;
  transition: 0.4s;
  margin-right: 20px;
}

.hamburger.open {
  transform: rotate(-45deg);
}

/* Media Query */
@media (max-width: 768px) {
  .navbar {
    padding: 10px 2%; /* Adjust padding for smaller screens */
    position: fixed;
  }

  .googleForm{
    width: 340px;
  }

  .navbar-links {
    display: none;
    flex-direction: column;
    position: fixed;
    top: 65px;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 1;
    padding: 10px;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  }

  .navbar-logo {
    font-family: sans-serif;
    display: flex;
    align-items: center; /* Center the logo vertically */
    margin-left: 8px;
  }

  .navbar-logo img {
    padding-top: 0px;
    height: 26px;
    /* Adjust the height of the logo as needed */
  }

  .navbar-logo h1 {
    font-size: 25px;
    margin-left: 5px;
    color: rgb(21, 21, 84);
  }

  .navbar-links li {
    padding-top: 10px;
  }

  .active-link {
    font-weight: bold;
    color: blue;
  }

  .mobile-menu {
    display: flex;
    padding-left: 20px;
    padding-top: 10px;
  }

  .hamburger-icon {
    display: block;
  }

  .Contact {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 100%;
    padding: 12px;
    margin-right: 5px;
    color: white;
    border: none;
    font-weight: bold;
    background-color: rgb(5, 5, 121);
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
  }
}

@media (min-width: 768px) {
  .navbar {
    padding: 10px 2%; 
  }

  .navbar-links {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
