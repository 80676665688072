/* Team.css */

.TeamCardsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 90%;
    margin: auto;
    font-family:'Montserrat', sans-serif;

    
    /* border: 2px solid; */
  }
  

  .TeamCards {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-bottom: 25px;
    width: 90%;
    justify-content: center;
    /* border: 2px solid; */
  }
  
  .team-card {
    background: #fff;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 24%;
    border-bottom: 8px solid darkblue ;
    
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;
    transition: transform 0.2s;
  

  
    .Tcard-content {
      padding: 15px;
  
      h2 {
        font-size: 1.5rem;
        margin: 10px 0;
      }
  
      p {
        font-size: 1rem;
        color: #666;
      }
  
      
    }
  
    &:hover {
      transform: scale(1.05);
    }
  }
  
  .Tcard-image{
    width: 100%;
    height: 100%;
    /* border: 1px solid; */
  }

  .TImg {
    width: 100%;
    height: 250px; /* Adjust the height as needed */
    object-fit: cover; /* Ensure the image fits the div and maintains aspect ratio */
    border-radius: 8px 8px 0 0;
    /* border: 1px solid red; */
  }

  .Tsocial-handles {
   
      font-size: 30px;
      color: #5623fb;
      margin-right: 10px;
      margin-top: 5px;
      transition: color 0.3s;
      /* border: 1px solid; */

      
    }

    .Tsocial-icon{
      color: #6c3fff;
    }
  
.twitter{
    margin-left: 10px;
}

@media(max-width:768px){
  .team-card {
    background: #fff;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 48%;
    border-bottom: 8px solid darkblue ;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;
    transition: transform 0.2s;
  

  
    .Tcard-content {
      padding: 10px;
      /* border: 1px solid; */
  
      h2 {
        font-size: 18px;
        margin: 2px 0;
      }
  
      p {
        font-size: 1rem;
        color: #666;
      }
  
      
    }
}

.TImg {
  width: 100%;
  height: 150px; /* Adjust the height as needed */
  object-fit: cover; /* Ensure the image fits the div and maintains aspect ratio */
  border-radius: 8px 8px 0 0;
  /* border: 1px solid red; */
}

.TeamCards {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding-bottom: 25px;
}

.TeamCardsContainer {
  width: 94%;
  margin: auto;
  overflow: hidden;
  /* border: 1px solid; */
}

.Tcard-content h2{
    font-size: 20px;
    width: 100%;
    /* border: 1px solid; */
    padding: 0px;
    margin: 0px;
}
.Tcard-content p{
  font-size: 10px;
  width: 100%;
  /* border: 1px solid; */
  padding: 0px;
  margin: 0px;
}

}

