.Scontainer{
    padding-top: 60px;
    display: flex;
    background-color: rgb(230, 255, 243);
}

.StoryClient{
    height: 200px;
    
}


@media (max-width: 768px) {
    .Scontainer{
        padding-top: 120px;
        display: list-item;
        list-style: none;
        background-color: rgb(230, 255, 243);
    }
    

}