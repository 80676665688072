
.about-us-container {
    background-color: aliceblue;
    height: 580px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgb(207, 194, 255));
    /* font-family:'Montserrat', sans-serif; */
    padding-top: 100px;
  }
  
  

  .about-us-banner h1{
    font-size: 45px;
    font-weight: bold;
  }
  
  .about-us-banner h3{
    font-size: 30px;
  }

  .card-container {
    display: flex;
    width: 90%;
    justify-content: space-around;
    height: 300px;
    margin-top: 30px;
  }
  
  .card {
    width: 30%;
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    height: 200px;
    text-align: center;
    margin: 15px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    border-bottom: 6px solid darkblue;
    font-family:'Montserrat', sans-serif;

  }
  
  .card h3 {
    font-size: 20px;
    padding-bottom: 20px;
    padding-top: 30px;
  }
  
  .card p {
    font-size: 16px;
  }
  
  /* Add margin to the middle card */
  .middle-card {
    margin-top: 50px;
    width: 30%;
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    font-family:'Montserrat', sans-serif;
    height: 200px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    border-bottom: 6px solid darkblue;

  }

  .middle-card h3{
    padding-bottom: 20px;
    padding-top: 30px;
  }

  .Cont2{
    width: 90%;
    margin: auto;
    font-family:'Montserrat', sans-serif;


  }
  
  .Features{
    background-color: white;
    width: 90%;
    margin: auto;
    /* border: 2px solid; */
    justify-content: center;
    font-family:'Montserrat', sans-serif;

  }

  .Features h2{
    font-family:'Montserrat', sans-serif;
    padding-bottom: 10px;
    padding-top: 20px;

  }

  
  

  .Fcard-container{
    display: flex;
    width: 100%;
    justify-content: space-around;
    min-height: 150px;
    margin-top: 40px;
  }

  .Fcard{
    width: 30%;
    /* background-image: linear-gradient(to right, rgba(255,0,0,0), rgb(207, 194, 255)); */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 6px;
    text-align: left;
    height: fit-content;
    margin: 10px;
    height: 90px;
    font-family:'Montserrat', sans-serif;
    border-left: 6px solid darkblue;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.19);
    /* border-bottom: 6px solid rgb(100, 100, 255); */
  }

  .Exist-container{
    width: 90%;
    margin: auto;
    font-family:'Montserrat', sans-serif;
    padding-top: 20px;
    

  }

  
  .Ecard-container{
    width: 100%;
    display: flex;
  }

  .Ecard{
    width: 30%;
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    height: 180px;
    text-align: left;
    margin: 15px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    border-bottom: 8px solid darkblue;
    font-family:'Montserrat', sans-serif;
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: thin;
  }

  .Ecard p{
    margin-top: 15px;
    margin-bottom: 15px;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 3px;
    
}
                    
::-webkit-scrollbar-track {
    border-radius: 10px;

}
                    
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: darkblue;
}


@media (max-width: 768px) {
  .card-container {
    display: list-item;
    list-style: none;
    width: 100%;
    justify-content: space-around;
    height: fit-content;
    margin-top: 30px;
  } 
  
  .card {
    width: 80%;
    background-color: white;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 10px;
    height: 100px;
    text-align: center;
    margin: 5px;
    margin-left: 25px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    border-bottom: 6px solid darkblue;
    font-family:'Montserrat', sans-serif;

  }

  .card h3 {
    font-size: 20px;
    padding-bottom: 10px;
    padding-top: 5px;
  }

  .middle-card {
    margin-top: 15px;
    margin-bottom: 15px;

    width: 80%;
    background-color: white;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    font-family:'Montserrat', sans-serif;
    height: 100px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    border-bottom: 6px solid darkblue;
    margin-left: 25px;
  }

  .about-us-banner h1{
    font-size: 28px;
    font-weight: bold;
  }
  
  .about-us-banner h3{
    font-size: 20px;
  }
  .middle-card h3{
    padding-bottom: 10px;
    padding-top: 0px;
  }
  .Exist-container{
    width: 90%;
    margin: auto;
    font-family:'Montserrat', sans-serif;
    padding-top: 20px;

  }

  .Ecard-container{
    width: 100%;
    display: list-item;
    list-style: none;
  }

  .Ecard{
    width: 80%;
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    height: fit-content;
    text-align: left;
    margin: 15px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    border-bottom: 8px solid darkblue;
    font-family:'Montserrat', sans-serif;
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: thin;
  }
  .Fcard-container{
    display: list-item;
    list-style: none;
    width: 100%;
    justify-content: space-around;
    height: fit-content;
    margin-top: 40px;
    padding-bottom: 20px;
  }

  .Fcard{
    width: 80%;
    /* background-image: linear-gradient(to right, rgba(255,0,0,0), rgb(207, 194, 255)); */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 6px;
    text-align: left;
    height: fit-content;
    margin: 10px;
    font-family:'Montserrat', sans-serif;
    border-left: 6px solid darkblue;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.19);
    /* border-bottom: 6px solid rgb(100, 100, 255); */
  }


}

.TeamHeading{
  width: 89%;
  margin: auto;
  padding: 0 25px  25px;
  font-family:'Montserrat', sans-serif;

}


.TeamHeading h3{
  padding: 0px ;
  text-align: center;
  font-size: 24px;
  /* border: 1px solid; */
  padding-bottom: 8px;
}

.THeading{
  width: fit-content;
  margin: auto;
  /* border: 1px solid; */
}

.TUnder{
  background-color: darkblue;
  height: 4px;;
  border-radius: 18px;
  border: none;
}


@media(max-width:768px){
  .TeamHeading{
    width: 89%;
    margin: auto;
    padding: 0 0  15px;
    font-family:'Montserrat', sans-serif;
    /* border: 1px solid; */
    font-size: 13px ;
  
  }
  
  .THeading{
    width: fit-content;
  }
  
  .TeamHeading h3{
    padding: 10px ;
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
    /* border: 1px solid; */
  }

  .THeading{
    width: 65%;
    margin: auto;
  }
  
}