.Concept-container{
    display: flex;
    height: 500px;
    padding-top: 10;
    margin: 0;
    /* border-radius: 0 0 15px 15px; */
    background-color: rgb(196, 207, 255);
    border: 2px solid aliceblue;
    text-align: center;
    /* font-family:'Montserrat', sans-serif; */
    padding: 25px;
    font-size: 30px;
    padding-left: 120px;
    padding-top: 60px;
}

.CSidebar{
    height: fit-content;
    padding-bottom: 50px;

}

.LeftConcepts{
    width: 40%;
    margin: 55px;
    padding:5%;
    padding-left: 10px; 
    text-align: left;   
} 

.LeftConcepts h1{
    padding-top: 35px;
    font-size: 35px;
}

.ConceptsBanner{
    width: 450px
    
}

@media (max-width: 768px) {
    .Concept-container{
        display: list-item;
        height: fit-content;
        padding-top: 0;
        margin: 0;
        /* border-radius: 0 0 15px 15px; */
        background-color: rgb(196, 207, 255);
        border: 2px solid aliceblue;
        text-align: center;
        /* font-family:'Montserrat', sans-serif; */
        padding: 25px;
        font-size: 30px;
        padding-left: 10px;
        padding-top: 60px;
        padding-right: 10px;
    }    

    .LeftConcepts{
        width: 90%;
        margin: 15px;
        padding:5%;
        padding-left: 10px; 
        text-align: left;   
    } 
    .LeftConcepts h1{
        padding-top: 35px;
        font-size: 20px;
    }
    .ConceptsBanner{
        width: 100%
        
    }
    .CSidebar{
        height: fit-content;
        padding-bottom: 50px;
    }
    


}
