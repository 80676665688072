/* ClientsSection.css */
.clients-section {
    text-align: left;
    padding: 10px 0;
    background-color: #ffffff;
    height: fit-content;
    font-family:'Montserrat', sans-serif;

  }
  
  .clients-section h2 {
    font-size: 24px;
    margin-bottom: 50px;
    padding-left: 5%;
  }
  
  .slick-dots {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
  }
  
  .slick-dots li {
    display: inline-block;
    margin: 0 5px;
  }
  
  .client-logo {
    text-align: center;
  }
  
  .client-logo img {
    max-width: 90%;
    height: auto;
    margin: 0 auto;
  }
  