.FloatingBtnDiv{
display: flex;
gap: 5px;
}

.floating-button {
    font-family:'Montserrat', sans-serif;
text-align: center;
    display: flex;
  position: fixed;
  right: 30px;
  bottom: 30px;
  background-color: #110080;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 10px;
  width: fit-content;
  box-shadow: 0 0 15px rgba(0,0,0,0.3);
  z-index: 1000; /* Ensures the button stays on top of other elements */
}

.floating-button:hover {
  background-color: #5a4fec; /* Example of hover effect */
}


@media (max-width: 768px) {
    .floating-button{
        bottom: 10px;
        right: 10px;
        border: none;
        width: fit-content;
        padding: 7px 10px;
    }

}