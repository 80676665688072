/* PrivacyPolicy.css */
.PrivacyCont{
    padding-top: 100px;
    padding-bottom: 20px;
}
.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .privacy-policy-heading {
    font-size: 24px;
    color: #3c1eff;
    margin-bottom: 20px;
  }
  
  .section-heading {
    font-size: 20px;
    color: #1b0d5a;
    margin-top: 20px;
  }
  
  .privacy-policy-text {
    font-size: 16px;
    color: #555;
    margin-bottom: 15px;
  }
  
  .privacy-policy-list {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  /* Customize the colors, fonts, and styles to match your website's design. */
  