/* Custom styles for the InstagramReelCarousel component */

.instagram-reel-carousel {
    width: 88%;
    margin: auto; /* Add a gap of 10px between slides */
    border-radius: 8px; /* Add a border radius of 8px */
    padding-top: 60px;
    padding-right: 10px;
    
  }

  .carousel-item{
    /* margin-right: 20px; */
    padding-right:310px;
  }
  
  .carousel-video {
    border-radius: 8px; /* Add a border radius to video elements */
  }
  
  /* Custom styles for the control buttons */
  