*{
  margin: 0;
  padding: 0;
  /* font-family:'Montserrat', sans-serif; */

}


.container{
  padding-top: 60px;
    display: flex;
    height: 500px;
    background-color: aliceblue;
}

span{
    color: rgb(103, 103, 255) ;
}
.LandingImg{
    width: 450px;

}

.BHead h1{
  padding-top: 15px;

}


.LandingText{
    width: 40%;
    margin: 55px;
    padding:5%;
    padding-left: 150px;

}

.LandingText h1{
    font-size: 35px;
}

.Talk a{
  color: white;
  text-decoration: none;
  font-size: 18px;
}

.Talk{
  margin-top: 12px;
  width: 150px;

}

.Topmate-button{
  margin-top: 12px;
  width: 200px;
  font-size: 18px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  background-color: rgb(33, 6, 130);
  border:2px solid  rgb(33, 6, 130);
}

.Topmate-button:hover{
  color: white;
  background-color: rgb(1, 1, 135);
}

.ContComp{
width: 100%;
height: 100px;
margin-top: 60px;
}

.YtSharkTank{
    margin-top: 75px;
    width: 100%;
    height: 500px;
    background-color: rgb(44, 136, 217);
}

/* App.css */
.landing-container {
    display: flex;
    width: 100%;
    height: 600px;
    background-color: #f0f0f0; /* Background color of the container */
    

}
  
  /* Style for the left side (tagline) */
  .left-side {
    width: 35%;
    /* padding: 100px; */
    /* border: 2px solid; */
    
  }

  .left-side h1  {
    padding-left: 120px;
    padding-top:140px ;
    font-size: 40px;
    font-family:'Montserrat', sans-serif;
    font-weight: bold;

  }
  .Falhari{
    font-weight: bolder;
    font-size: 50px;
}

  .line{
    width: 280px;
    background-color: rgb(102, 59, 255);
    height: 3px;
    margin-top: 25px;
    margin-left: 120px;
  }

  .Sharktank{
    width: 250px;
    margin-left: 70px;
  }


  .tagline {
    font-size: 30px;
    color: #333; /* Text color */
  }
  
  /* Style for the right side (video embed) */
  .right-side {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid; */
    padding-right: 10px;
  }
  
  .Insta{
    width: 100%;
    /* background-color: rgb(226, 235, 255); */
    padding: 20px;
  }
  
  /* Media Query */

  @media (max-width: 768px) {
    .container{
      display: list-item;
      height: fit-content;
      width: 100%;
      margin-top: 0;
      padding-top: 120px;
  }

  .Talk{
    width: 100px;
    
  }
  .Talk a{
    font-size: 14px;
    width: 120px;
    font-weight: 400;
    color: #fff;
  }

  .Topmate-button{
    font-size: 14px;
    width: 160px;
    border: 1px solid;
    
  }
  .landing-container {
    display: list-item;
    width: 90%;
    padding: 5px;
    margin: auto;
    list-style: none;
    align-items: center;
    height: fit-content;
    background-color: #f0f0f0; /* Background color of the container */
  
  }
    
  .LandingText{
    width: 85%;
    margin: 0px;
    padding:10px;
    padding-left: 10%;   

}

  .LandingText h1{
    font-size: 20px;
  }

  .LandingImg{
    width: 90%;
    align-items: center;
}

.YtSharkTank{
  margin-top: 5;
  width: 100%;
  height: fit-content;
  background-color: aliceblue;
  margin-bottom: 0;
  padding-bottom: 0;
}

.Sharktank{
  margin-left: 20px;
  border: 1px solid;
  object-fit: cover;
  width: 200px;
  margin-bottom: 20px;
  border-radius: 6px;

}


.left-side h1  {
  padding-left: 20px;
  padding-top:10px ;
  font-size: 20px;
  font-family:'Montserrat', sans-serif;
  font-weight: bold;
  width: 100%;

}

.left-side {
  width: 90%;
  /* padding: 100px; */
  /* border: 2px solid; */
  
}

.line{
  width: 200px;
  background-color: rgb(101, 62, 254);
  height: 3px;
  margin-top: 5px;
  margin-left: 20px;
  margin-bottom: 15px;
}

.right-side {
  display: list-item;
  justify-content: center;
  align-items: center;
  /* border: 2px solid; */
  padding-left: 0px;
}


}