/* HorizontalCards.css */
.HCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 90%; /* Adjust the max width as needed */
  margin: auto;
  font-family: 'Montserrat', sans-serif;
}

.horizontal-card {
  flex-basis: calc(50% - 20px); /* 50% width with margin in between */
  margin: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  overflow: hidden;
  width: 100%;
  display: flex;
}

.card-image {
 max-width: 33%;
  flex: 1;/* Take up 33% of the width */
  padding: 20px; /* Optional: Add padding around the image */
}

.card-image img {
  width: 100%;
  height: auto;
}
.card-content {
  flex: 1;
  padding: 20px;
}

.card-content h2 {
  font-size: 24px;
  margin: 0;
}

.card-content p {
  font-size: 16px;
  margin: 10px 0;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  background-color: rgb(5, 5, 121);
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
  width: 120px;
}

button:hover {
  background-color: #0056b3;
}

.BHead{
  width:89%;
  margin: auto;
  font-family: 'Montserrat', sans-serif;

}

.pagination{
  margin-bottom: 10px;
  position: absolute;
  left: 46%;
}
.PaginationBtn{
  background-color: white;
  border: 2px solid ;
  color: black;
}
@media (max-width: 768px) {
.HCards {
  flex-direction: column; /* Display cards in a column */
  align-items: center; /* Center cards horizontally */
}

  
button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 10px;
  width: 120px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  background-color: rgb(5, 5, 121);
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
}



.pagination{
  left: 36%;
}
.PaginationBtn{
  margin-bottom: 10px;

}

.horizontal-card {
  flex-basis: 100%; /* Take full width in a column layout */
  margin: 10px 0; /* Adjust margin for spacing between cards */
  display: flex; /* Display children in a row layout */
  flex-direction: column; /* Display children in a column layout */
}

.card-image {
  flex: 1;
  max-width: 90%;
  padding: 20px;
  /* border: 1px solid; */
}
.card-content {
  text-align: left; /* Center content */
  padding: 10px 10; /* Padding below title and description */
}

.card-content h2 {
  margin: 0;
}

.pagination {
  text-align: center; /* Center pagination buttons */
}
}




.tags-container{
  display: flex;
  margin-bottom: 10px;
  /* border: 1px solid; */
  gap: 5px;
 
}

.tags{
  font-size: 14px;
  background-color: rgb(230, 239, 246);
  border-radius: 10px;
  padding: 2px;
  /* border: 1px solid rgb(169, 193, 214); */
}
.tagsDiv{
  margin-top: 8px;
}




@media only screen and (min-width: 1440px){
  .description{
    height: 70px;
    overflow: hidden;
  }
  .read-more-tags{
    height: 15px;
    padding-top: 10px;
  }
  .tags-container{
    gap: 5px;
    margin-bottom: 10px;
   
  }

}